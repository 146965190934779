import $http from '../../axios-intance';
import {Message, MessageBox, Notification} from 'element-ui';

export async function findAll({commit, state, dispatch}) {
    commit('setPreloader', true);

    await $http.get('/api/solicitud-de-garantia', {params: state.filters})
        .then((response) => {
            commit('setRequestsGuarantees', response.data.data);
            commit('setPreloader', false);
        }).catch((error) => {
            commit('setPreloader', false);
            Notification.error({
                title: 'Error',
                message: 'Error al obtener las solicitudes de garantía',
                type: 'error',
                duration: 3000,
                customClass: 'notification-box',
            });
        });
}

export async function getTypesStates({commit, dispatch}) {
    await dispatch('getParametersByCodeTable', 'GS01')
        .then(data => commit('setStates', data))
        .catch(e => console.error(e));
}

export async function getTypesStatesEvaluation({commit, dispatch}) {
    await dispatch('getParametersByCodeTable', 'GAS01')
        .then(data => commit('setStatesEvaluation', data))
        .catch(e => console.error(e));
}

export async function getApprovalReasons({commit, dispatch}) {
    await dispatch('getParametersByCodeTable', 'GAR01')
        .then(data => commit('setApprovalReasons', data))
        .catch(e => console.error(e));
}

export async function getParametersByCodeTable({commit, state, dispatch}, code) {
    return new Promise(async (resolve, reject) => {

        await $http.get('/api/parametros-por-codigo/' + code)
            .then((response) => {
                resolve(response.data.data);
            }).catch((error) => {
                Notification.error({
                    title: 'Error',
                    message: 'Error al obtener los parametros',
                    type: 'error',
                    duration: 3000,
                    customClass: 'notification-box',
                });
                reject();
            });
    });
}


export async function getProductsRequest({commit, state, dispatch}, filter) {

    return new Promise(async (resolve, reject) => {

        if (filter.length >= 3) {
            let params = {
                params: {
                    filter: filter
                }
            };

            await $http.get('/api/buscar-productos', params)
                .then((response) => {
                    resolve(response.data.data);
                }).catch((error) => {
                    Notification.error({
                        title: 'Error',
                        message: 'Error al obtener los productos',
                        type: 'error',
                        duration: 3000,
                        customClass: 'notification-box',
                    });
                    reject();
                });
        } else {
            resolve([]);
        }
    });
}

export async function getProducts({commit, state, dispatch}, filter) {
    await dispatch('getProductsRequest', filter)
        .then(data => commit('setProducts', data))
        .catch(e => console.error(e));
}

export async function getProductsToSend({commit, state, dispatch}, filter) {
    await dispatch('getProductsRequest', filter)
        .then(data => commit('setProductsToSend', data))
        .catch(e => console.error(e));
}

export async function getProductsToSendTwo({commit, state, dispatch}, filter) {
    await dispatch('getProductsRequest', filter)
        .then(data => commit('setProductsToSendTwo', data))
        .catch(e => console.error(e));
}

export async function getProductsToSendThree({commit, state, dispatch}, filter) {
    await dispatch('getProductsRequest', filter)
        .then(data => commit('setProductsToSendThree', data))
        .catch(e => console.error(e));
}
export async function create({commit, state, dispatch}, files) {

    return new Promise(async (resolve, reject) => {

        commit('setSending', true);
        commit('setPreloader', true);

        let form_data = new FormData();

        const user_b2b = JSON.parse(localStorage.getItem('user_b2b'));

        commit('setContactWarehouseIdRequestGuarantee', user_b2b.id);

        for (const property in state.request_guarantee) {
            if (!property.includes('images')) {
                form_data.append(property, state.request_guarantee[property]);
            }
        }

        let keys = [
            'images_product',
            'other_images',
            'images_guide'
        ];

        keys.map(key => {
            for (let i = 0; i < files[key].length; i++) {
                form_data.append(key + '_loaded[' + i + ']', files[key][i]);
            }
        });

        await $http.post('/api/solicitud-de-garantia', form_data)
            .then(async (response) => {
                await dispatch('findAll');
                commit('setModalShow', false);
                commit('resetRequestGuarantee');
                commit('setSending', false);
                commit('setPreloader', false);

                Notification.success({
                    title: 'Éxito!',
                    message: 'Solicitud de garantía creada correctamente',
                    type: 'success',
                    duration: 3000,
                    customClass: 'notification-box',
                });
            }).catch((error) => {
                commit('setSending', false);
                commit('setPreloader', false);

                Notification.error({
                    title: 'Error',
                    message: 'Error al crear la solicitud de garantía',
                    type: 'error',
                    duration: 3000,
                    customClass: 'notification-box',
                });
            });
    });
}

export async function update({commit, state, dispatch}, files) {
    commit('setSending', true);
    commit('setPreloader', true);

    let form_data = new FormData();

    for (const property in state.request_guarantee) {
        if (property !== 'attachments_deleted') {
            form_data.append(property, state.request_guarantee[property]);
        }
    }

    let keys = [
        'images_evaluation',
        'images_guide_send',
        'images_delivery_customer'
    ];

    keys.map(key => {
        if (files[key]) {
            for (let i = 0; i < files[key].length; i++) {
                form_data.append(key + '_loaded[' + i + ']', files[key][i]);
            }
        }
    });

    for (let i = 0; i < state.request_guarantee.attachments_deleted.length; i++) {
        form_data.append('attachments_deleted[' + i + ']', state.request_guarantee.attachments_deleted[i].id);
    }

    await $http.post('/api/solicitud-de-garantia/' + state.request_guarantee.id, form_data)
        .then(async (response) => {
            await dispatch('findAll');
            await dispatch('getApprovalReasons');
            commit('setModalShow', false);
            commit('resetRequestGuarantee');
            commit('setSending', false);
            commit('setPreloader', false);

            Notification.success({
                title: 'Éxito!',
                message: 'Solicitud de garantía actualizada correctamente',
                type: 'success',
                duration: 3000,
                customClass: 'notification-box',
            });
        }).catch((error) => {
            commit('setSending', false);
            commit('setPreloader', false);

            Notification.error({
                title: 'Error',
                message: 'Error al actualizar la solicitud de garantía',
                type: 'error',
                duration: 3000,
                customClass: 'notification-box',
            });
        });
}

export async function updateWithNewState({commit, state, dispatch}, files) {
    commit('setSending', true);
    commit('setPreloader', true);

    let form_data = new FormData();

    for (const property in state.request_guarantee) {
        if (property !== 'attachments_deleted') {
            form_data.append(property, state.request_guarantee[property]);
        }
    }

    let keys = [
        'images_evaluation',
        'images_guide_send',
        'images_delivery_customer'
    ];

    keys.map(key => {
        if (files[key]) {
            for (let i = 0; i < files[key].length; i++) {
                form_data.append(key + '_loaded[' + i + ']', files[key][i]);
            }
        }
    });

    for (let i = 0; i < state.request_guarantee.attachments_deleted.length; i++) {
        form_data.append('attachments_deleted[' + i + ']', state.request_guarantee.attachments_deleted[i].id);
    }

    await $http.post('/api/solicitud-de-garantia/nuevo-estado/' + state.request_guarantee.id, form_data)
        .then(async (response) => {
            await dispatch('findAll');
            await dispatch('getApprovalReasons');
            commit('setModalShow', false);
            commit('resetRequestGuarantee');
            commit('setSending', false);
            commit('setPreloader', false);

            Notification.success({
                title: 'Éxito!',
                message: 'Solicitud de garantía actualizada correctamente',
                type: 'success',
                duration: 3000,
                customClass: 'notification-box',
            });
        }).catch((error) => {
            commit('setSending', false);
            commit('setPreloader', false);

            Notification.error({
                title: 'Error',
                message: 'Error al actualizar la solicitud de garantía',
                type: 'error',
                duration: 3000,
                customClass: 'notification-box',
            });
        });
}

export async function findForId({commit, state, dispatch}, id) {

    commit('setPreloader', true);

    await $http.get('/api/solicitud-de-garantia/' + id)
        .then((response) => {
            commit('setRequestGuarantee', response.data.data);

            commit('setPaymentDate', !!state.request_guarantee.state_payment_id);

            let step = 1;

            if (state.request_guarantee.request_guarantee_state && state.request_guarantee.request_guarantee_state.state) {
                let step_code = state.request_guarantee.request_guarantee_state.state.code_parameter;

                let step_obj = state.steps.find(s => s.code === step_code);

                if (step_obj) {
                    step = step_obj.step;
                }
            }

            if (!!state.request_guarantee.product) {
                commit('setProducts', [state.request_guarantee.product]);
            }

            if (!!state.request_guarantee.product_to_send) {
                commit('setProductsToSend', [state.request_guarantee.product_to_send]);
            }

            if (!!state.request_guarantee.product_to_send_two) {
                commit('setProductsToSendTwo', [state.request_guarantee.product_to_send_two]);
            }

            if (!!state.request_guarantee.product_to_send_three) {
                commit('setProductsToSendThree', [state.request_guarantee.product_to_send_three]);
            }

            commit('setStep', step);
            commit('setStepAvailable', step);
            commit('setPreloader', false);

        }).catch((error) => {

            commit('setPreloader', false);

            Notification.error({
                title: 'Error',
                message: 'Error al obtener la solicitud de garantía',
                type: 'error',
                duration: 3000,
                customClass: 'notification-box',
            });
        });
}

export async function deleteAttachment({commit, state}, attachment) {
    await MessageBox.confirm('¿Está seguro de eliminar el adjunto?', 'Eliminar adjunto', {
        confirmButtonText: 'Aceptar',
        cancelButtonText: 'Cancelar',
        type: 'warning',
        customClass: 'notification-danger'
    }).then(async (result) => {
        commit('addAttachmentDeleteRequestGuarantee', attachment);
        commit('deleteAttachmentRequestGuarantee', attachment);
    }).catch((e) => {
        console.error(e);
    });
}

export async function destroy({commit, state, dispatch}, request_guarantee) {

    await MessageBox.prompt('¿Está seguro de anular la solicitud de garantía?', 'Anular', {
        confirmButtonText: 'Anular',
        cancelButtonText: 'Cancelar',
        type: 'warning',
        inputPlaceholder: 'Observaciones',
        inputType: 'textarea',
        customClass: 'notification-danger',
        inputErrorMessage: 'Observaciones invalidas'
    }).then(async (result) => {

        if (!!result.value) {

            request_guarantee.observation = result.value;

            commit('setPreloader', true);

            await $http.post('/api/solicitud-de-garantia/anular/' + request_guarantee.id, request_guarantee)
                .then(async (response) => {
                    await dispatch('findAll');
                    commit('setPreloader', false);

                    Notification.success({
                        title: 'Éxito!',
                        message: 'Solicitud de garantía anulada correctamente',
                        type: 'success',
                        duration: 3000,
                        customClass: 'notification-box',
                    });
                }).catch((error) => {
                    commit('setPreloader', false);

                    Notification.error({
                        title: 'Error',
                        message: 'Error al anular la solicitud de garantía',
                        type: 'error',
                        duration: 3000,
                        customClass: 'notification-box',
                    });
                });
        } else {
            Message.error('Las observaciones son obligatorias para anular una solicitud de garantía');
        }
    }).catch((e) => {
        console.error(e);
    });
}

export async function downloadAttachment({commit}, attachment) {

    commit('setPreloader', true);

    await $http({
        url: '/api/solicitud-de-garantia/descargar-adjunto/' + attachment.id,
        method: 'GET',
        responseType: 'blob', // important
    }).then((response) => {

        commit('setPreloader', false);

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', attachment.original_name);
        document.body.appendChild(link);
        link.click();
    }).catch((error) => {
        Notification.error({
            title: 'Error!',
            message: error.message,
            type: 'error',
            duration: 3000,
            customClass: 'notification-box',
        });

    });
}

export async function getSellers({commit, state}) {
    let leadership_id = state.filters.leadership_id;

    commit('setPreloader', true);

    if (leadership_id === null) {
        await $http.get('/api/sellers').then((response) => {
            commit('setSellers', response.data.data);
            commit('setPreloader', false);
        }).catch(() => commit('setPreloader', false));

    } else {

        await $http.get('/api/sellers/' + leadership_id).then((response) => {
            commit('setSellers', response.data.data);
            commit('setPreloader', false);
        }).catch(() => commit('setPreloader', false));
    }
}

export async function getLeaderships({commit, state}) {
    await $http.get('/api/leaderships').then((response) => {
        commit('setLeaderships', response.data.data);
    });
}
