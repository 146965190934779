export default [
    {
        path: '/b2b/inicio',
        component: resolve => require(['./../default-b2b.vue'], resolve),
        children: [
            {
                path: '',
                component: resolve => require(['./../views/home.vue'], resolve),
                meta: {
                    title: 'Inicio',
                    permission: 'B2B Ver menú Inicio',
                    breadcrumb: [{
                        text: ' Inicio',
                        href: '/',
                    }]
                }
            }, {
                path: '/b2b/tracking-pedidos',
                component: resolve => require(['./../views/orders-history.vue'], resolve),
                meta: {
                    title: 'Historial de Pedidos',
                    permission: 'B2B Ver menú Tracking de pedidos',
                    breadcrumb: [{
                        text: ' Inicio',
                        href: '/',
                    }]
                }
            }, {
                path: '/b2b/cartera',
                component: resolve => require(['./../views/receivable.vue'], resolve),
                meta: {
                    title: 'Cartera',
                    permission: 'B2B Ver menú Cartera',
                    breadcrumb: [{
                        text: ' Inicio',
                        href: '/',
                    }]
                }
            }, {
                path: '/b2b/catalogo',
                component: resolve => require(['./../views/catalog.vue'], resolve),
                meta: {
                    title: 'Catálogo',
                    permission: 'B2B Ver menú Catálogo',
                    breadcrumb: [{
                        text: ' Inicio',
                        href: '/',
                    }]
                }
            }, {
                path: '/b2b/productos-relativos',
                component: resolve => require(['./../views/relative-products-form.vue'], resolve),
                meta: {
                    title: 'Catálogo',
                    breadcrumb: [{
                        text: ' Inicio',
                        href: '/',
                    }]
                }
            }, {
                path: '/b2b/:id/detalle',
                component: resolve => require(['./../views/product-detail.vue'], resolve),
                meta: {
                    title: 'Detalle de Producto',
                    breadcrumb: [{
                        text: ' Inicio',
                        href: '/',
                    }]
                }
            }, {
                path: '/b2b/asistente-reposicion',
                component: resolve => require(['./../views/replacement-assistant.vue'], resolve),
                meta: {
                    title: 'Asistente de Reposición',
                    permission: 'B2B Ver asistente de reposición',
                    breadcrumb: [{
                        text: ' Inicio',
                        href: '/',
                    }]
                }
            }            , {
                path: '/b2b/solicitud-garantias',
                component: resolve => require(['../views/guarantees/guarantees.vue'], resolve),
                meta: {
                    title: 'Solicitud de garantías',
                    permission: 'B2B Ver menú Garantías',
                    breadcrumb: [{
                        text: 'Solicitud de garantía',
                        href: '/',
                    }]
                }
            }
        ]
    }
];
