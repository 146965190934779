import {request_guarantee} from './state';

export function setContactWarehouseIdRequestGuarantee(state, contact_warehouse_id) {
    state.request_guarantee.contact_warehouse_id = contact_warehouse_id;
}

export function setContactWarehouseIdRequestGuaranteeFilters(state, contact_warehouse_id) {
    state.filters.contact_warehouse_id = contact_warehouse_id;
}

export function setModalShow(state, modal_show) {
    state.modal.show = modal_show;
}

export function setStep(state, step) {
    state.step = step;

    const state_creation = state.request_guarantee.request_guarantee_states[step - 1];

    if (state_creation) {
        state.request_guarantee.observation = state_creation.observation;
    } else {
        state.request_guarantee.observation = '';
    }
}

export function setObservationRequestGuarantee(state, observation) {
    state.request_guarantee.observation = observation;
}

export function setStepAvailable(state, step_available) {
    state.step_available = step_available;
}

export function setRequestsGuarantees(state, requests_guarantees) {

    requests_guarantees.map(rg => {
        let step = 1;
        let step_code = '';

        if (rg.request_guarantee_state && rg.request_guarantee_state.state) {
            step_code = rg.request_guarantee_state.state.code_parameter;

            let step_obj = state.steps.find(s => s.code === step_code);

            if (step_obj) {
                step = step_obj.step;
            }
        }

        rg.only_view = step > 1 || step_code === 'PGS9' || step_code === 'PGS5';
    });


    state.requests_guarantees = requests_guarantees;
}

export function setRequestGuarantee(state, request_guarantee) {

    const state_creation = request_guarantee.request_guarantee_states.find(s => s.state.code_parameter === 'PGS1');

    if (state_creation) {
        request_guarantee.observation = state_creation.observation;
    }

    state.request_guarantee = request_guarantee;
    state.request_guarantee.attachments_deleted = [];
}

export function resetRequestGuarantee(state) {
    state.request_guarantee = request_guarantee();
}

export function setAction(state, action) {
    state.action = action;
}

export function addAttachmentDeleteRequestGuarantee(state, attachment) {
    state.request_guarantee.attachments_deleted.push(attachment);
}

export function deleteAttachmentRequestGuarantee(state, attachment) {
    let keys = [
        'images_product',
        'other_images',
        'images_guide'
    ];

    let request_guarantee = JSON.parse(JSON.stringify(state.request_guarantee));

    keys.map(key => {
        let index_attachment = request_guarantee[key].findIndex(a => a.id === attachment.id);

        if (index_attachment >= 0) {
            request_guarantee[key].splice(index_attachment, 1);
        }
    });

    state.request_guarantee = request_guarantee;
}

export function resetPageFilters(state) {
    state.filters.page = 1;
}

export function setStates(state, states) {
    state.states = states;
}

export function setStatesEvaluation(state, states_evaluation) {
    state.states_evaluation = states_evaluation;
}

export function setApprovalReasons(state, approval_reasons) {
    state.approval_reasons = approval_reasons;
    state.approval_reasons_filtered = approval_reasons;
}

export function setApprovalReasonsFiltered(state, approval_status_id) {

    if (approval_status_id) {
        const evaluation_state = state.states_evaluation.find(s => s.id === approval_status_id);

        if (evaluation_state) {
            if (evaluation_state.code_parameter === 'PGAS3') {
                state.approval_reasons_filtered = state.approval_reasons.filter(ar => ar.code_parameter.includes('RGRS'));
            } else {
                state.approval_reasons_filtered = state.approval_reasons.filter(ar => ar.code_parameter.includes('AGRS'));
            }
        }
    }

    state.request_guarantee.approval_reason_id = null;
}

export function setProducts(state, products) {
    state.products = products;
}

export function setProductsToSend(state, products_to_send) {
    state.products_to_send = products_to_send;
}

export function setProductsToSendTwo(state, products_to_send_two) {
    state.products_to_send_two = products_to_send_two;
}

export function setProductsToSendThree(state, products_to_send_three) {
    state.products_to_send_three = products_to_send_three;
}

export function setProductToSendId(state, products_to_send_id) {
    state.request_guarantee.product_to_send_id = products_to_send_id;
}

export function setSending(state, sending) {
    state.sending = sending;
}

export function setSellers(state, sellers) {
    state.sellers = sellers;
}

export function setLeaderships(state, leaderships) {
    state.leaderships = leaderships;
}

export function setLeadershipIdParams(state, user_id) {

    let leadership = state.leaderships.find(l => l.user_id === user_id);

    if (leadership) {
        state.filters.leadership_id = leadership.id;
    }
}

export function setSellerIdParams(state, seller_id) {
    state.filters.seller_id = seller_id;
}

export function setPreloader(state, preloader) {
    state.preloader = preloader;
}

export function changePaymentDate(state) {
    state.payment_data = !state.payment_data;
}

export function setPaymentDate(state, payment_data) {
    state.payment_data = payment_data;
}
