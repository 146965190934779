import $http from '../../axios-intance';
import {Notification} from 'element-ui';

export async function fetchManagements({commit, state}, restart_pagination = true) {
    commit('setPreloader', true);

    let parameters = {params: state.params}

    if (restart_pagination){
        parameters.params.page = 1;
    }

    await $http.get('/api/gestion-comercial', parameters).then((response) => {
        commit('setPreloader', false);
        commit('setManagements', response.data.data);
    }).catch((error) => {
        commit('setPreloader', false);
        Notification.error({
            title: '¡Error!',
            message: error.message,
            type: 'error',
            duration: 1500,
            customClass: 'notification-box'
        });
    });
}

export async function getContact({commit, state}, warehouse) {
    await $http.get(`/api/establecimientos-vendedor?ide=${warehouse}`).then((response) => {
        commit('ecommerce/setSelectedCustomer', response.data.data, {root: true});
    }).catch((error) => {
        Notification.error({
            title: '¡Error!',
            message: error.message,
            type: 'error',
            duration: 1500,
            customClass: 'notification-box'
        });
        commit('error', error.message);
    });
}

export async function fetchCities({commit}, requestParameters) {
    await $http.get('/api/ciudades', requestParameters)
        .then((response) => {
            commit('setCities', response.data.data);
        })
        .catch((error) => {
            swal({
                title: 'error',
                text: error.message,
                type: 'error'
            });
            commit('CityError', error.message);
        });
}

export async function getSellers({commit, state}) {
    let leadership_id = state.params.leadership_id;

    commit('setSellerIdParams', null);

    if (leadership_id === null) {
        await $http.get('/api/sellers').then((response) => {
            commit('setSellers', response.data.data);
        });
    } else {
        await $http.get('/api/sellers/' + leadership_id).then((response) => {
            commit('setSellers', response.data.data);
        });
    }
}

export function setPerPagePagination({commit, dispatch}, perPage) {
    commit('setPerPage', parseInt(perPage.target.value));
    dispatch('fetchManagements');
}

export async function setPagePagination({commit, dispatch}, page) {
    commit('setPage', parseInt(page));
    await dispatch('fetchManagements');
}

export async function getLeaderships({commit, state}) {
    await $http.get('/api/leaderships').then((response) => {
        commit('setLeaderships', response.data.data);
    });
}
