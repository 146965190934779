import {defaultSelectedWarehouse} from "./state";

export function setWarehouses(state, warehouses) {

    state.warehouses = [];

    if (warehouses.length) {
        warehouses.map(w => {
            if (!!w.criteria_values && !!w.criteria_values.length) {
                w.customer_type_id = w.criteria_values[0].parameter_id;
            }
        });

        state.selectedWarehouse = JSON.parse(JSON.stringify(warehouses[0]))

        state.warehouses = warehouses;
    }
}

export function setWarehouse(state, warehouse) {
    state.selectedWarehouse = JSON.parse(JSON.stringify(warehouse));
}

export function setActionWarehouses(state, action) {
    state.actionWarehouse = action;
}

export function warehouseError(state, error) {
    state.error = true
    state.errorMessage = error
}

//Establece el valor por defecto de selectedWarehouse
export function resetSelectedWarehouse(state) {
    let id = state.selectedWarehouse.id
    Object.assign(state.selectedWarehouse, defaultSelectedWarehouse())
    if (id) {
        state.selectedWarehouse.id = id
    }
}
